@font-face {
  font-family: 'penguin';
  src:
    url('fonts/penguin.ttf?5wbav2') format('truetype'),
    url('fonts/penguin.woff?5wbav2') format('woff'),
    url('fonts/penguin.svg?5wbav2#penguin') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'penguin' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-alert-fill:before {
  content: "\e900";
}
.icon-arrow-back:before {
  content: "\e901";
}
.icon-arrow-right-circle-fill:before {
  content: "\e902";
}
.icon-arrow-right-up:before {
  content: "\e903";
}
.icon-arrow1-down:before {
  content: "\e904";
}
.icon-arrow1-left:before {
  content: "\e905";
}
.icon-arrow1-right:before {
  content: "\e906";
}
.icon-arrow1-up:before {
  content: "\e907";
}
.icon-arrow1s-down:before {
  content: "\e908";
}
.icon-arrow4-left:before {
  content: "\e909";
}
.icon-arrow4-right:before {
  content: "\e90a";
}
.icon-arrow7-down:before {
  content: "\e90b";
}
.icon-arrow7-right:before {
  content: "\e90c";
}
.icon-arrow8-down:before {
  content: "\e90d";
}
.icon-arrow8-up:before {
  content: "\e90e";
}
.icon-bell:before {
  content: "\e90f";
}
.icon-box-drawing-character:before {
  content: "\e910";
}
.icon-calendar:before {
  content: "\e911";
}
.icon-check-circle-fill:before {
  content: "\e912";
}
.icon-check:before {
  content: "\e913";
}
.icon-check1:before {
  content: "\e914";
}
.icon-close-panel:before {
  content: "\e915";
}
.icon-close:before {
  content: "\e916";
}
.icon-close1:before {
  content: "\e917";
}
.icon-close2:before {
  content: "\e918";
}
.icon-dots-vertical:before {
  content: "\e919";
}
.icon-download:before {
  content: "\e91a";
}
.icon-facebook-circle-fill:before {
  content: "\e91b";
}
.icon-facebook:before {
  content: "\e91c";
}
.icon-flag1:before {
  content: "\e91d";
}
.icon-gear:before {
  content: "\e91e";
}
.icon-headphone-fill:before {
  content: "\e91f";
}
.icon-info2:before {
  content: "\e920";
}
.icon-initial-circle:before {
  content: "\e921";
}
.icon-investor:before {
  content: "\e922";
}
.icon-ipo:before {
  content: "\e923";
}
.icon-link:before {
  content: "\e924";
}
.icon-linkedin-square-fill:before {
  content: "\e925";
}
.icon-lock-fill:before {
  content: "\e926";
}
.icon-memo:before {
  content: "\e927";
}
.icon-menu:before {
  content: "\e928";
}
.icon-minus-square-fill:before {
  content: "\e929";
}
.icon-more-horizotal:before {
  content: "\e92a";
}
.icon-newspicks:before {
  content: "\e92b";
}
.icon-pen-fill:before {
  content: "\e92c";
}
.icon-people-fill:before {
  content: "\e92d";
}
.icon-people2:before {
  content: "\e92e";
}
.icon-person-fill:before {
  content: "\e92f";
}
.icon-person:before {
  content: "\e930";
}
.icon-plus-square-fill:before {
  content: "\e931";
}
.icon-plus:before {
  content: "\e932";
}
.icon-question-circle-fill:before {
  content: "\e933";
}
.icon-question-circle:before {
  content: "\e934";
}
.icon-remove-fill:before {
  content: "\e935";
}
.icon-search:before {
  content: "\e936";
}
.icon-side-panel:before {
  content: "\e937";
}
.icon-sort-fill:before {
  content: "\e938";
}
.icon-sort1:before {
  content: "\e939";
}
.icon-spot1:before {
  content: "\e93a";
}
.icon-startup:before {
  content: "\e93b";
}
.icon-tag:before {
  content: "\e93c";
}
.icon-trash-fill:before {
  content: "\e93d";
}
.icon-unlock-fill:before {
  content: "\e93e";
}
.icon-x:before {
  content: "\e93f";
}
